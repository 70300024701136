
import 'reflect-metadata'
import { Component, Mixins } from 'vue-property-decorator'
import Dark from '@/mixins/dark'
import { getApp, getService } from '@/helpers/feathers'
import { Getter, namespace } from 'vuex-class'
import { NotificationPayload } from '@/store/notifications/notification-payload.interface'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import { RoleDashboard, RoleDTO } from './DashboardRoles.interface'
import { DashboardTranslationsWithID } from '@/interfaces/DashboardTranslationsWithID.interface'

const NotificationStore = namespace('notification')

/**
 * Rendert ein Liste mit den Rollendashboards
 */
@Component({
  components: {
    CustomDialog
  }
})
export default class DashboardRoles extends Mixins(Dark) {
  /**
   * Liste lädt, wenn true
   */
  public loading = true

  /**
   * Zeigt einen Action Button unterhalb der Auflistung an, der das Erstellen
   * eines neuen Dashboards ermöglicht
   */
  public showCreateButton = true

  /**
   * Wenn 'true', wird die Prompt zum Löschen eines Dashboards angezeigt
   */
  public removePromptOpen = false

  /**
   * Wenn 'true', wird ein Dialog zum Anlegen eines Dashboards angezeigt.
   */
  public createPromptOpen = false

  /**
   * Datensatz des Dashboards, welches gelöscht werden soll
   */
  public dashboardToRemove?: RoleDashboard

  /**
   * Array mit Rollendashboards vom Server
   */
  public data: RoleDashboard[] | {} = {}

  /**
   * Enthält alle Rollen, für die es noch kein Dashboard gibt.
   */
  public availableRoles: RoleDTO[] = []

  /**
   * Zeigt an, ob die Daten der Rollen gerade geladen werden.
   */
  public loadingAvailableRoles = false

  /**
   * Zeigt an, ob gerade ein Dashboard angelegt wird.
   */
  public creating = false

  /**
   * Zeigt an, ob ein Dashboard gerade gelöscht wird.
   */
  public deleting = false

  /**
   * Togglet einen Toaster und zeigt diesen an
   */
  @NotificationStore.Action('toggleToast')
  public toggleToast!: (payload: NotificationPayload) => void

  /**
   * Übersetzungsdaten der Dashboards aus dem Store.
   */
  @Getter('dashboardTranslations/dashboards')
  public translations!: DashboardTranslationsWithID[]

  /**
   * Triggert die Funktion, die sich die Rollendashboards vom Server holt
   */
  public async created(): Promise<void> {
    await this.getData()
  }

  /**
   * Holt sich die Rollendashboards vom Server
   */
  public async getData(): Promise<void> {
    try {
      this.data = (
        await (await getApp()).service('dashboard-administration').find()
      ).data
    } catch (error) {
      throw error
    }
    this.loading = false
  }

  /**
   * Wird ausgeführt, wenn der Button zum Anlegen eines neuen Dashboards
   * betätigt wird.
   */
  public async openCreateDashboardPrompt(): Promise<void> {
    this.createPromptOpen = true
    this.loadingAvailableRoles = true
    await this.getAvailableRolesForDashboards()
    this.loadingAvailableRoles = false
  }

  /**
   * Wird ausgeführt, wenn der Button zum Anlegen eines neuen Dashboards
   * betätigt wird.
   *
   * @param propertyID - ID der Rolle.
   */
  public async createDashboardAndClose(propertyID: number): Promise<void> {
    this.creating = true
    await this.createDashboard(propertyID)
    this.creating = false
    this.createPromptOpen = false
  }

  /**
   * Holt die Rollen, die noch keine Dashboard-Zuordnung haben und speichert
   * diese in [[this.availableRoles]].
   *
   * @returns Array von Rollen.
   */
  private async getAvailableRolesForDashboards(): Promise<void> {
    this.availableRoles = []

    const allRoles = (await (await getService('role')).find()) as RoleDTO[]

    this.availableRoles = allRoles
      .filter(r => r.DashboardID === null)
      .sort((a: RoleDTO, b: RoleDTO) => a.name.localeCompare(b.name))
  }

  /**
   * Erstellt ein neues Dashboard mit einer angegebenen Rollen-ID.
   *
   * @param propertyID - ID der Rolle, für die ein Dashboard angelegt werden soll.
   */
  public async createDashboard(propertyID: number): Promise<void> {
    let toastText = this.$t(
      'dashboardRoles.notification.add.success'
    ).toString()
    let toastType = 'success'

    await this.getAvailableRolesForDashboards()

    if (
      this.availableRoles.findIndex(r => r.PropertyID === propertyID) === -1
    ) {
      toastText = this.$t('dashboardRoles.notification.add.error').toString()
      toastType = 'error'
      throw new Error(`Failed to create new dashboard! PropertyID not existing`)
    }

    try {
      await (
        await getService('dashboard-administration')
      ).create({
        propertyID
      })

      await this.getData()
    } catch (error) {
      toastText = this.$t('dashboardRoles.notification.add.error').toString()
      toastType = 'error'
      throw new Error(`Failed to create new dashboard! ${error}`)
    } finally {
      this.toggleToast({
        text: toastText,
        type: toastType,
        btnText: '',
        data: {}
      })
    }
  }

  /**
   * Öffnet den Prompt zum Löschen eines Dashboards
   *
   * @param dashboard - Datensatz des Dashboards welches gelöscht werden soll
   */
  public triggerRemovalPrompt(dashboard: RoleDashboard): void {
    this.dashboardToRemove = dashboard
    this.removePromptOpen = true
  }

  /**
   * Löscht ein Dashboard mit allem, was unter dem Dashboard hängt
   *
   * @param id - ID des Dashboards welches gelöscht werden soll
   */
  public async removeDashboard(id: number): Promise<void> {
    this.deleting = true
    try {
      await (await getService('dashboard')).remove(id)
      const payload: NotificationPayload = {
        type: 'success',
        text: this.$t('dashboardRoles.notification.remove.success').toString()
      }
      this.toggleToast(payload)
    } catch (error) {
      const payload: NotificationPayload = {
        type: 'error',
        text: this.$t('dashboardRoles.notification.remove.error').toString()
      }
      this.toggleToast(payload)
      this.resetRemoval()
      throw error
    } finally {
      this.deleting = false
    }
    await this.getData()
    this.resetRemoval()
  }

  /**
   * Setzt den Löschvorgang zurück
   */
  public resetRemoval(): void {
    this.removePromptOpen = false
    this.dashboardToRemove = undefined
  }

  /**
   * Gibt den Namen des Elements zurück.
   *
   * @param item - Das Element, für das der Name zurückgegeben
   * werden soll.
   * @returns Name des übergebenen Navigations-Elements.
   */
  public getName(item: RoleDashboard): string {
    let res = item.propertyName
    const translations = this.translations.find(t => t.id === item.id)
    if (translations) {
      const currentTranslation = translations.translations.find(
        translation => translation.language === this.$i18n.locale
      )

      const fallbackTranslation = translations.translations.find(
        translation => translation.language === this.$i18n.fallbackLocale
      )

      if (currentTranslation && currentTranslation.translation) {
        res = currentTranslation.translation
      } else if (fallbackTranslation && fallbackTranslation.translation) {
        res = fallbackTranslation.translation
      }
    }
    return res
  }
}
