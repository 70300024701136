
import { Component, Vue } from 'vue-property-decorator'
import DashboardRoles from '@/components/DashboardRoles/DashboardRoles.vue'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

/**
 * Auflistung der Rollendashboards.
 */
@Component({
  components: {
    DashboardRoles,
    BaseFrame
  }
})
export default class DashboardRoleList extends Vue {}
